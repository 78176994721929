import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../../../context/provider/UserProvider';
import {
    useHistory
} from "react-router-dom";
// import LogoAbexa2018 from '../../../assets/images/LogoAbexa2018.png'
import { Timer } from './Timer';
import { Slideshow, Slide } from '../../components/utils/SliderShow';
import logoAbexa from '../../../assets/images/LogoAbexa-Web.png'
import logoATU from '../../../assets/images/LogoATU-MuniCIP .png'

import { Modal } from '../../components/modal/Modal';
import { useModal } from '../../../hooks/useModal';
import { notify } from '../../../utils/utils';
import { useUsuarios } from '../../../hooks/usuarios/useUsuarios';



export const Header = ({openMenu}) => {
    const {cambiarClaveUsuario,isOpen, openModal, closeModal} = useUsuarios()
    
    const urlPlaminApi = process.env.REACT_APP_PLAMIN_API + '/api';

    const dropDown = useRef()
    const { stateUser, signOut } = useContext(UserContext)
    const history = useHistory();

    const [claveActual,setClaveActual] = useState('')
    const [nuevaClave1,setNuevaClave1] = useState('')
    const [nuevaClave2,setNuevaClave2] = useState('')

    const [ojo1,setOjo1] = useState(false)
    const [ojo2,setOjo2] = useState(false)
    const [ojo3,setOjo3] = useState(false)
    
    const singOutSession = () => {        
        cerrarSesion()
        // mqttDisconnect();
        // signOut()
        // history.push('/')
    }

    const toggleUser = () => {
        const isClosed = dropDown.current.classList.contains('hidden')
        isClosed && dropDown.current.classList.remove('hidden')
        !isClosed && dropDown.current.classList.add('hidden')
    }

    const hashearClave = async (clave) => {
        const msgUint8 = new TextEncoder().encode(clave);                      
        const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);          
        const hashArray = Array.from(new Uint8Array(hashBuffer));                 
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		
        return hashHex;
    }


    const cerrarSesion= async () => {



        // return
        // window.location.href = `https://wso2is-service.abexa.pe/oidc/logout?id_token_hint=${stateUser.id_token}&post_logout_redirect_uri=http://192.168.1.96:3001/PantallaCarga&state=logout_success`;
        window.location.href = `https://wso2is-service.abexa.pe/oidc/logout?id_token_hint=${stateUser.id_token}&post_logout_redirect_uri=https://etu.plaminoficial.com/PantallaCarga&state=logout_success`;
        //  const response = await AuthFetch({url:'https://wso2is-service.abexa.pe/oidc/logout'})
        //const response = await AuthFetch({url:'http://192.168.1.96:3001/CierreSesion'})

    }




    const cambiarClave = async () => {
        if((claveActual != '') && (nuevaClave1 != '') && (nuevaClave2 != '')){
            if(nuevaClave1 == nuevaClave2){
                let claveAnteriorHash = await hashearClave(claveActual)
                let claveNuevaHash = await hashearClave(nuevaClave1)

                await cambiarClaveUsuario(claveAnteriorHash,claveNuevaHash)
            }else{
                notify('Las claves nuevas deben coincidir','error')
            }
        }else{
            notify('Llenar todos los campos','error')
        }
    }

    return (
        <>
            <div className="header">
                <nav>
                    <div className="width-full px-4 lg:px-2">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="items-center  w-[110px] hidden lg:flex">
                                <button onClick={openMenu} type="button" className="lg:inline-flex  items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                    <i className="fas fa-bars text-[20px]"></i>
                                </button>
                            </div>
                            <div className="flex-1 flex items-stretch justify-start lg:items-center lg:justify-center">
                                <div className="flex-shrink-0 flex justify-center items-center w-[250px]" >
                                    <img src={logoATU} className='lg:w-[150px] lg:h-[50px] w-[300px] mt-2' alt=""/> 
                                </div>
                            </div>

                            <div className="relative w-[180px] h-[100%] flex flex-col items-center lg:right-0 inset-auto">
                                {/* <img className='rounded-[70px] w-[80px] h-[100%] absolute right-0' onClick={()=>toggleUser()} src={fotoUrl} alt=''/> */}
                                <div className='relative w-full h-1/2'>
                                    <Timer clases={'lg:hidden absolute right-0 top-2'}></Timer>
                                </div>

                                <div className='h-1/2 flex relative w-full justify-end items-center gap-3'>
                                    <button onClick={() => toggleUser()} className="px-1 py-1 hover:bg-blue-600 rounded">
                                        <span className=" text-[15px]">{ stateUser.nomUsuario }</span>
                                    </button>
                                    <a href='/principal'><i className='lg:hidden fa fa-home text-[20px]'></i></a>

                                    <div ref={dropDown} className="absolute top-full right-0 hidden z-50 w-44 bg-white rounded divide-y divide-blue-100 shadow dark:bg-[#003968]">
                                        <ul className="py-1 text-sm text-white-700 dark:text-white-900" aria-labelledby="dropdownButton">
                                            <li><a onClick={() => openModal()} className="block py-2 px-4 hover:bg-blue-100 dark:hover:bg-blue-600 dark:hover:text-white">Cambiar Clave</a></li>
                                            <li><a onClick={() => {singOutSession()}} className="block py-2 px-4 hover:bg-blue-100 dark:hover:bg-blue-600 dark:hover:text-white">Cerrar Sesión</a></li>
                                        </ul>
                                    </div> 
                                </div>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <Modal
                isOpen={isOpen} closeModal={closeModal} action={() => cambiarClave()}
                title={'Cambiar Clave'} 
            >
                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[300px] px-4">
                        <div>
                            <div>
                                <label>Clave actual:</label><br/>
                                <div className='flex items-center'>
                                    <input className='text-black w-full rounded-md p-1 mt-1' type={ojo1 ? '' : 'password'} onChange={(elem) => setClaveActual(elem.target.value)}/>
                                    <i onClick={() => setOjo1(!ojo1)} className={`fa ${ojo1 ? 'fa-eye' : 'fa-eye-slash'} pl-2 cursor-pointer`} aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <label>Nueva clave:</label><br/>
                                <div className='flex items-center'>
                                    <input className='text-black w-full rounded-md p-1 mt-1' type={ojo2 ? '' : 'password'} onChange={(elem) => setNuevaClave1(elem.target.value)}/>
                                    <i onClick={() => setOjo2(!ojo2)} className={`fa ${ojo2 ? 'fa-eye' : 'fa-eye-slash'} pl-2 cursor-pointer`} aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <label>Confirmar nueva clave:</label><br/>
                                <div className='flex items-center'>
                                    <input className='text-black w-full rounded-md p-1 mt-1' type={ojo3 ? '' : 'password'} onChange={(elem) => setNuevaClave2(elem.target.value)}/>
                                    <i onClick={() => setOjo3(!ojo3)} className={`fa ${ojo3 ? 'fa-eye' : 'fa-eye-slash'} pl-2 cursor-pointer`} aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
        
}